@import 'src/sass/variables';

.FullPageLoader {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;
  top: 0;
  left: 0;

  .loader-container {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    background-color: $black-90;
    width: 150px;
    height: 150px;
    @media screen and (max-width: $tablet) {
      width: 100px;
      height: 100px;
      overflow-y: auto;
    }
  }

  .loader-modal {
    max-width: 680px;
    width: 100%;
    height: 312px;
    margin: 15% auto 0;
    border-radius: 24px;
    text-align: center;

    margin: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -65%);

    @media screen and (max-width: $tablet) {
      position: absolute;
      max-width: 100%;
      height: 100vh;
      margin: 0;
      left: 0;
      top: 0;
      transform: none;
      border-radius: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .animation {
    width: 115px;
    margin: 0 auto;
    margin-bottom: -20px;
  }
}
