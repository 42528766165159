@charset "utf-8";
@import './theme.scss';
@import './margin-padding-helper.scss';
@import './fonts/fonts.scss';
@import './variables.scss';

body {
  overflow-x: hidden;
  position: relative;
}

.modal-fix {
  position: fixed;
  width: 100%;
}

.title {
  color: $main-title;
  letter-spacing: 1.5px;
}

.full-height {
  min-height: 100vh;

  &.columns {
    margin-top: 0;
  }
}

.is-clickable {
  cursor: pointer;
}

.is-underlined {
  text-decoration: underline;
}

.is-stuck-to-the-floor {
  bottom: 0;
  position: absolute;
}

.is-floating-centre {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: inherit;
}

.is-fit-to-parent {
  width: inherit;
}

.main-container {
  position: relative;
  width: 100%;
  background-color: $black-90;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 20px 0px 0px 20px;
}

.button {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  filter: none !important;
  height: 50px;
  border-radius: 25px;
  border: 2px solid $blue-grey-3;
  background-color: $white-grey;

  &-compact {
    border-radius: 20px;
  }

  &-icon {
    @extend .is-icon-3;
    @extend .button-compact;

    &::before {
      color: $white-grey;
    }
  }

  &.is-primary {
    background: $electric-red;
    &:disabled {
      background-color: $blue-grey-1 !important;
      opacity: 0.7;
      color: $blue-grey-4;
    }
  }

  &.mobile {
    width: 100%;
    margin-left: 5%;

    @media screen and (max-width: 492px) {
      margin-left: 8px;
    }
  }

  &.is-secondary {
    border: 2px solid $blue-grey-3;
    color: $black-90;
  }

  @media screen and (max-width: $tablet) {
    height: 46px;
  }
}

.is-text-link {
  color: $lightBlue;
  cursor: pointer;
  text-decoration: underline;
}

.is-red-text-link {
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

.select {
  &:not(:last-child) {
    margin-right: 30px;
  }

  select {
    border-radius: 50px;
    width: 100%;
    font-size: 1.125rem;
    border: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  }
}

.rounded-container {
  background-color: $blue-grey-8;
  border-radius: 16px;
  padding: 0 23px;

  &:not(:first-child) {
    margin-top: 30px;
  }
}

@media (max-width: $tablet) {
  .main-container {
    .header {
      padding: 15px 15px 0px 15px;
    }

    .body {
      padding: 15px;
    }

    border-radius: 0;
  }
}
