@import 'src/sass/variables';

.offers-unavailable-page {
  height: 90vh;
  display: flex;
  flex-direction: column;

  > header {
    background: $white;
    position: relative;
    z-index: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000000;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    height: 110px;

    .vodapay-logo {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 40px;
      top: 30px;

      @media screen and (max-width: $tablet) {
        width: 32px;
        height: 32px;
        left: 20px;
        top: 20px;
      }
    }
    @media screen and (max-width: $tablet) {
      box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
      height: 75px;
    }
  }

  > .offers-unavailable-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5rem;

    > .offers-unavailable-panel {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: $white;
      height: fit-content;

      padding: 2rem;
      margin-bottom: 2rem;

      box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 2px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      > header {
        text-align: center;
        margin-bottom: 2rem;

        > h2 {
          color: $black;
          font-weight: 700;
          font-size: 2rem;
          @media screen and (max-width: $mobile) {
            font-size: 22px;
          }
        }

        > p {
          font-size: 1rem;
          line-height: 24px;
          text-align: center;
          color: #000000;
          margin: 2rem auto 1rem;
          @media screen and (max-width: $mobile) {
            font-size: 14px;
            margin-top: 1rem;
          }
        }
      }

      > img {
        margin-top: 2rem;
        width: 100%;
        max-width: 250px;
        height: 260px;
      }

      @media screen and (max-width: $tablet) {
        box-shadow: none;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: 0;
      }
    }

    @media screen and (max-width: $tablet) {
      padding-top: 0;
    }
  }
}
