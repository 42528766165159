@import '/src/sass/responsive-variables.scss';

div#root {
  background-image: url('~assets/images/gray-background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: $tablet) {
    background-image: none !important;
  }
}

.toast-help-container {
  z-index: 50;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
