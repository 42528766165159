@import 'src/sass/variables';

.image-button-block {
  background: #ffffff;
  border: 1.5px solid #b6b6ba;
  border-radius: 8px;
  position: relative;

  width: 170px;
  height: 120px;

  font-weight: 700;
  font-size: 14px;
  color: $black-header;
  cursor: pointer;

  &.active {
    border: 2px solid $black-header;
  }

  > .checkbox-img {
    position: absolute;
    width: 20px;
    height: 20px;

    right: 0.5rem;
    top: 0.5rem;
  }

  @media screen and (max-width: $mobile) {
    width: 140px;
  }
}

.image-button-rect {
  display: flex;
  align-items: center;
  height: 60px;
  width: 260px;
  padding: 1rem;
  gap: 1rem;

  background: #ffffff;
  border: 2px solid #b6b6ba;
  border-radius: 8px;

  font-size: 16px;
  color: $black-header;
  cursor: pointer;

  &.active {
    border: 2px solid $black-header;
  }

  > img {
    width: 22px;
    height: 22px;
  }

  > .checkbox-img {
    width: 20px;
    height: 20px;
    margin-left: auto;
  }

  @media screen and (max-width: $tablet) {
    width: 90%;
  }
}
