@import 'src/sass/variables';
@import 'node_modules/bulma/bulma';

.get-started-page {
  height: 90vh;
  display: flex;
  flex-direction: column;

  > header {
    background: hsl(0deg, 0%, 100%);
    position: relative;
    z-index: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000000;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    height: 110px;

    .vodapay-logo {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 40px;
      top: 30px;

      @media screen and (max-width: $tablet) {
        width: 32px;
        height: 32px;
        left: 20px;
        top: 20px;
      }
    }

    @media screen and (max-width: $tablet) {
      box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
      height: 75px;
    }
  }

  > .get-started-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5rem;

    .get-started-panel {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: $white;
      height: fit-content;
      max-width: 700px;

      padding: 2rem;

      box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 2px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      > header {
        text-align: center;
        margin-bottom: 2rem;

        > h2 {
          color: $black;
          font-weight: 700;
          font-size: 32px;
          @media screen and (max-width: $mobile) {
            font-size: 22px;
          }
        }

        > p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin-top: 1rem;
        }
      }

      > section {
        display: flex;
        gap: 1.5rem;
        justify-content: center;
        width: 100%;
        height: fit-content;
        margin-bottom: 2.5rem;
      }

      > p {
        text-align: center;
        max-width: 550px;
        font-size: 1rem;
        @media screen and (max-width: $mobile) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      @media screen and (max-width: $tablet) {
        margin-bottom: 1.5rem;
        box-shadow: none;
      }
    }
    @media screen and (max-width: $tablet) {
      padding-top: 0;
    }
  }

  @media screen and (max-width: $tablet) {
    height: fit-content;
  }
}
