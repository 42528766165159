@import 'src/sass/variables';
@import 'node_modules/bulma/bulma';

.bank-portal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;

  .bank-portal-panel {
    background-color: $white;
    width: 600px;
    padding: 1.5rem;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .iframe-container {
      height: 500px;
      display: flex;
      justify-content: center;

      .animation {
        width: 300px;
      }

      iframe {
        height: 500px;
        width: 100%;
      }

      .hidden {
        display: none;
      }

      @media screen and (max-width: $tablet) {
        height: 350px;
      }
    }

    @media screen and (max-width: $tablet) {
      box-shadow: none;
      margin-bottom: 4rem;
    }
  }

  @media screen and (max-width: $tablet) {
    padding-top: 1rem;
  }
}
