@import 'src/sass/variables';

.upload-title {
  color: $dark-grey;
  font-size: 24px;
  @media screen and (max-width: $tablet) {
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
  }
}
.no-outline {
  outline: none;
}

.cloud-image {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}
.upload-subtext {
  color: $dark-grey;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  @media screen and (max-width: $tablet) {
    //styleName: Label / Small Regular (10);
    font-size: 10px;
    line-height: 13px;
  }
}

.fullwidth {
  width: 100%;
}

@media screen and (min-width: $tablet) {
  .center-box {
    padding-top: 20px;
  }
  #upload-text {
    color: $dark-grey;
    font-size: $size-4;
    margin-right: 7px;
  }

  #mobile-text {
    display: none;
  }

  #grey-drag-text {
    color: $dark-grey;
    font-size: $size-4;
    margin-right: 7px;
  }

  .resizable-div {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: $tablet) {
  .cloud-image {
    width: 20.16px;
    height: 20.16px;
    margin-right: 7px;
  }
  .center-box {
    padding-top: 15px;
  }

  #mobile-text {
    margin-right: 7px;
    color: $dark-grey;
    font-size: 14px;
    line-height: 140%;
    font-weight: 400;
  }

  #upload-text {
    display: none;
  }

  #grey-drag-text {
    display: none;
  }
}

.drop-text {
  padding-top: 12px;
}

.inner-upload-container {
  border: 1px dashed $blue-grey-6;
  border-radius: 15px;
}

.height-limit {
  min-height: 69px;
}

.min-height {
  min-height: 273px;

  @media screen and (max-width: $tablet) {
    min-height: 173px;
  }
}

.padding-drag-area,
.padding-drag-area-active {
  padding-top: 33px;
  padding-bottom: 33px;
  padding-left: 29px;
  padding-right: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media screen and (max-width: $tablet) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.padding-drag-area-active {
  padding-top: 22px;
  padding-bottom: 22px;

  @media screen and (max-width: $tablet) {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.file-upload {
  border: 1.5px solid $dark-grey;
  border-radius: 15px;
  padding: 1px;
  min-height: 74px;
  position: relative;

  :hover {
    cursor: pointer;
  }

  &.valid-file-drag {
    background-color: $blue-grey-7;
  }

  .uploaded-file-bubble {
    border-radius: 22px;
    background-color: $blue-grey-1;
    box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.15);
    font-size: $size-7;
    padding: 6px 10px;
    position: relative;
    margin-left: 5px;

    .uploaded-doc-name {
      padding-right: 20px;
      padding-left: 20px;
      overflow-wrap: break-word;
    }

    .close-button {
      content: url('../../assets/images/close-icon.svg');
      height: 20px;
      width: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;

      padding: 0px;
      border: 0px;
    }

    .close-button.id-center {
      right: 5px;
    }

    .close-button.non-id {
      right: 2px;
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: 5px;
    }
  }

  .reject-text {
    color: $dark-grey;
    font-size: $size-5;
  }

  .right-text {
    text-align: right;
  }

  .checkmark {
    position: absolute;
    right: -2px;
    top: -2px;
  }

  .id-subtext {
    font-size: 0.5rem;
  }

  .hidden {
    visibility: hidden;
  }

  .error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.drag-over {
  border: 2px solid $teal;
  padding: 0px;
}

.drag-reject {
  border: 2px solid $yellow;
  padding: 0px;
}

.upload-success {
  border: 2px solid $teal;
  padding: 0px;
}

.has-padding {
  padding: 20px;
}
