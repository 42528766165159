@import 'src/sass/variables';

.offer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .glossary-title {
    font-weight: 700;
    font-size: 32px;
    color: $black;
  }
  .glossary-content {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 40% 60%;

    p {
      color: $black;
      font-size: 14px;
      border-bottom: #a8acb8;
      border-width: 1px;
      border-bottom-style: solid;
      margin-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .glossary-term {
    font-weight: 700;
  }
}

.offer-panel {
  background-color: $white;
  max-width: 1000px;
  padding: 1.5rem;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  > header {
    text-align: center;
    margin-bottom: 2rem;
    > h2 {
      color: $black;
      font-weight: 700;
      font-size: 32px;
    }

    > p {
      font-weight: 400;
      font-size: 16px;
      margin-top: 1rem;
    }
  }

  .card {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ceced1;
    border-radius: 8px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: none;

    &.offer-selected {
      border: 2px solid $black-header;
    }

    h3 {
      text-align: center;
      padding: 0.5rem 0;
      color: #0c0c1a;
      font-family: Roboto;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
    }

    .company-logo {
      width: auto;
      height: 64px;
      max-width: 230px;
    }

    .card-content {
      display: grid;
      grid-template-columns: 65% 35%;
      grid-template-rows: repeat(5, 30px);
      margin-top: 1rem;
      min-height: 200px;
      padding: 1rem;
      width: 100%;

      p {
        font-size: 14px;
        color: $black;
        &.amount {
          text-align: end;
          font-weight: 700;
        }

        &:nth-child(2n) {
          font-weight: 700;
          text-align: right;
        }
      }
    }

    > footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 1rem;
      > a {
        color: $link-teal;
        text-decoration: underline;
      }

      > .checkbox-label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.4rem;
        cursor: pointer;
        > .checkbox-text {
          margin-left: 1rem;
          color: $black;
          font-size: 16px;
        }

        > img {
          height: 20px;
          border-radius: 5px;
        }
      }
    }
  }

  > footer {
    margin: 2rem 0 1rem 4rem;
    a {
      color: #007c92;
    }
  }

  @media screen and (max-width: $tablet) {
    margin-bottom: 1.5rem;
    box-shadow: none;
    > footer {
      margin: 2rem 0 1rem 2rem;
    }
  }
}
