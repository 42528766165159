@import './responsive-variables.scss';

$sizes: (1, 2, 3, 4, 5, 6, 7, 8);
$positions: ('top', 'left', 'bottom', 'right');
$bulmaSizes: ($size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7, $size-8);
$i: 1;
.has-margin-0 {
  margin: 0 !important;
}
.has-padding-0 {
  padding: 0 !important;
}

@each $size in $sizes {
  $sizee: nth($bulmaSizes, $i);
  $i: $i + 1;
  .has-margin-#{$size} {
    margin: $sizee !important;
  }
  .has-negative-margin-#{$size} {
    margin: -$sizee !important;
  }
  .has-padding-#{$size} {
    padding: $sizee !important;
  }
  .has-margin-x-#{$size} {
    margin-left: $sizee !important;
    margin-right: $sizee !important;
  }
  .has-padding-x-#{$size} {
    padding-left: $sizee !important;
    padding-right: $sizee !important;
  }
  .has-margin-y-#{$size} {
    margin-top: $sizee !important;
    margin-bottom: $sizee !important;
  }
  .has-padding-y-#{$size} {
    padding-top: $sizee !important;
    padding-bottom: $sizee !important;
  }

  @media (max-width: 768px) {
    .has-mobile-padding-#{$size} {
      padding: $sizee !important;
    }
    .has-mobile-padding-x-#{$size} {
      padding-left: $sizee !important;
      padding-right: $sizee !important;
    }
    .has-mobile-padding-y-#{$size} {
      padding-top: $sizee !important;
      padding-bottom: $sizee !important;
    }

    .has-mobile-padding-0 {
      padding: 0 !important;
    }

    .has-mobile-padding-x-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .has-mobile-padding-y-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .has-mobile-margin-#{$size} {
      margin: $sizee !important;
    }
    .has-mobile-margin-x-#{$size} {
      margin-left: $sizee !important;
      margin-right: $sizee !important;
    }
    .has-mobile-margin-y-#{$size} {
      margin-top: $sizee !important;
      margin-bottom: $sizee !important;
    }

    .has-mobile-margin-0 {
      margin: 0 !important;
    }

    .has-mobile-margin-x-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .has-mobile-margin-y-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @each $position in $positions {
    .has-margin-#{$position}-#{$size} {
      margin-#{$position}: $sizee !important;
    }
    .has-padding-#{$position}-#{$size} {
      padding-#{$position}: $sizee !important;
    }
    .has-negative-margin-#{$position}-#{$size} {
      margin-#{$position}: -$sizee !important;
    }
    .has-margin-#{$position}-0 {
      margin-#{$position}: 0 !important;
    }
    .has-padding-#{$position}-0 {
      padding-#{$position}: 0 !important;
    }

    @media (max-width: 768px) {
      .has-mobile-padding-#{$position}-#{$size} {
        padding-#{$position}: $sizee !important;
      }

      .has-mobile-negative-margin-#{$position}-#{$size} {
        margin-#{$position}: -$sizee !important;
      }

      .has-mobile-padding-#{$position}-0 {
        padding-#{$position}: 0 !important;
      }

      .has-mobile-margin-#{$position}-#{$size} {
        margin-#{$position}: $sizee !important;
      }

      .has-mobile-margin-#{$position}-0 {
        margin-#{$position}: 0 !important;
      }
    }
  }
}

// icon sizes
$j: 1;
@each $size in $sizes {
  $sizee: nth($bulmaSizes, $j);
  $j: $j + 1;
  .is-icon-#{$size} {
    font-size: $sizee;
  }
}
