@import 'src/sass/variables';
@import 'node_modules/bulma/bulma';

.employment-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;

  .employment-details-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $white;
    height: fit-content;
    min-width: 630px;

    padding: 2rem;
    margin-bottom: 2rem;

    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    > header {
      text-align: center;
      margin-bottom: 2rem;
      > h2 {
        color: $black;
        font-weight: 700;
        font-size: 2rem;

        @media screen and (max-width: $tablet) {
          font-size: 22px;
        }
      }
    }

    > section {
      width: 100%;
      height: fit-content;
      margin-bottom: 2.5rem;
      > form {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        @media screen and (max-width: $tablet) {
          gap: 1rem;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      min-width: 90%;
      box-shadow: none;
      padding: 0;
    }
  }

  @media screen and (max-width: $tablet) {
    padding-top: 2rem;
  }
}
