@import 'src/sass/variables';

.ProgressBar {
  background: $white;
  position: relative;
  z-index: 2;

  @media screen and (max-width: $tablet) {
    box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  }

  .vodapay-logo {
    position: absolute;
    left: 40px;
    top: 31px;
    width: 50px;
    height: 50px;

    @media screen and (max-width: $tablet) {
      left: 20px;
      top: 24px;
    }
  }

  .progress-block {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $black-pure;
    backdrop-filter: blur(25px);

    .progress-container {
      padding: 20px 30px;
      flex-wrap: wrap;
      margin: auto;
      width: 70%;

      .columns:not(:last-child) {
        margin-bottom: 0px;
      }

      .column {
        position: relative;
        justify-content: left;
        align-items: center;

        .progress-step-inner {
          margin-left: 5px;
          margin-right: 6px;
          margin-top: 4px;
          display: inline-block;
          vertical-align: middle;
          background-color: $white;
          border-radius: 50%;
          width: 10px;
          height: 10px;

          &.completed {
            width: 20px;
            height: 20px;
            border-color: $black-ninety;
            background-color: $electric-red;
          }

          &.active {
            width: 20px;
            height: 20px;
            border-color: $black-ninety;
          }
        }

        .progress-step {
          margin-left: 6px;
          margin-right: 6px;
          display: inline-block;
          vertical-align: middle;
          background-color: $black-pure;
          border-radius: 50%;
          width: 10px;
          height: 10px;

          .icon-check-mark {
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            color: $white;
            cursor: auto;
          }

          &.completed {
            width: 30px;
            height: 30px;
            border-color: $black-ninety;
          }

          &.active {
            width: 30px;
            height: 30px;
            border-color: $black-ninety;
          }
        }

        .step-bar {
          margin: auto;
          display: flex;
          flex-direction: row;
        }

        .left-side-border-radius-2px {
          border-radius: 2px 0px 0px 2px;
        }

        .right-side-border-radius-2px {
          border-radius: 0px 2px 2px 0px;
        }

        .step-progress {
          background-color: $electric-red;
          height: 2px;
        }

        .step-spacer {
          background-color: $blue-grey-six;
          height: 2px;
        }

        .step-number {
          color: $electric-red;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .step-label {
          margin-top: 13px;
          margin-left: auto;
          margin-right: auto;
          font-size: smaller;

          color: $blue-grey-4;

          &.completed {
            color: $blue-grey-three;
          }

          &.active {
            color: $red;
          }
        }
      }
    }
  }

  .mobile-progress {
    .mobile-step {
      width: 100%;
      height: 2px;
      &.completed {
        background-color: $electric-red;
      }
    }
  }

  .mobile-heading {
    text-align: center;

    .current-step-heading-mobile {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 130%;
      color: $black-ninety;
    }

    .step-name-heading-mobile {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $electric-red;
    }
  }

  .mobile-back-arrow-button {
    position: absolute;
    top: 35.74px;
    left: 16.41px;
    cursor: pointer;
  }

  .mobile-save-for-later-button {
    position: absolute;
    top: 35.74px;
    right: 16.41px;
    cursor: pointer;
    color: yellow;
  }
}
// without progress bar common header style
.page-header-common {
  background: hsl(0deg, 0%, 100%);
  position: relative;
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  height: 123px;

  @media screen and (max-width: $tablet) {
    box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
    height: 90px !important;
  }
}
.page-header-common .vodapay-logo {
  position: absolute;
  left: 40px;
  top: 31px;

  @media screen and (max-width: $tablet) {
    left: 16px;
    top: 22px;
  }
}
