@import 'src/sass/variables';
@import 'node_modules/bulma/bulma';

.upload-statements-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 4rem;

  @media screen and (max-width: $tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .upload-statements-panel {
    background-color: $white;
    max-width: 704px;
    width: 100%;
    padding: 1.5rem;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    > header {
      text-align: center;
      margin-bottom: 1.5rem;

      > h2 {
        font-weight: 500;
        font-size: 30px;
        color: $black;
        margin-bottom: 1.5rem;
      }

      > p {
        font-size: 16px;
        color: $black;
      }
    }

    @media screen and (max-width: $tablet) {
      box-shadow: none;
    }
  }

  .inner-upload-container > .column {
    width: 100%;
  }
  .columns.has-padding.resizable-div {
    flex-wrap: wrap;
    gap: 1rem;

    .uploaded-file-bubble {
      margin: 0 !important;
      text-align: left;
      box-shadow: none;
      overflow: hidden;

      p.uploaded-doc-name {
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @media screen and (max-width: $tablet) {
        margin-bottom: 10px !important;
      }
    }
  }
}
