@import '../../sass/variables.scss';

article.carousel-container {
  display: flex;
  align-items: center;

  .carousel-button {
    width: 72px;
    height: 72px;

    &.enabled {
      cursor: pointer;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  section#carousel {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow-x: auto;
    display: flex;

    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    scroll-snap-type: mandatory;
    -ms-scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(100%);
    -ms-scroll-snap-points-x: repeat(100%);

    scrollbar-color: #c2c2c2 #f2f2f2;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 0.6rem;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f2;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #c2c2c2;
      border-radius: 1rem;
    }

    &.hide-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    > * {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      scroll-snap-align: center;
    }
    > :first-child {
      margin-left: auto;
    }
    > :last-child {
      margin-right: auto;
    }
  }

  &.only-desktop {
    @media screen and (max-width: $tablet) {
      .carousel-button {
        display: none;
      }

      section#carousel {
        flex-direction: column;
        > * {
          margin-bottom: 0.5rem;
          margin-right: 0;
          margin-left: 0;
          scroll-snap-align: center;
        }
      }
    }
  }
}
