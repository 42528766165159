// Colours
$black: hsl(0, 0%, 4%) !default; // #0a0a0a
$black-bis: hsl(0, 0%, 7%) !default; // #121212
$black-ter: hsl(0, 0%, 14%) !default; // #242424

$grey-darker: hsl(0, 0%, 21%) !default; // #363636
$grey-dark: hsl(0, 0%, 29%) !default; // #4a4a4a
$grey: hsl(0, 0%, 48%) !default; // #7a7a7a
$grey-light: hsl(0, 0%, 77%) !default; // #c4c4c4
$grey-lighter: hsl(0, 0%, 86%) !default; // #dbdbdb
$grey-lightest: hsl(0, 0%, 93%) !default; // #ededed

$white-ter: hsl(0, 0%, 96%) !default; // #f5f5f5
$white-bis: hsl(0, 0%, 98%) !default; // #fafafa
$white: hsl(0, 0%, 100%) !default; // #ffffff

$orange: hsl(14, 100%, 53%) !default; // #ff470f
$yellow: hsl(48, 100%, 67%) !default; // #ffdd57
$green: hsl(90, 100%, 26%) !default; // #428500
$turquoise: hsl(171, 100%, 41%) !default; // #00d1b2
$lightBlue: hsl(188, 100%, 39.6%) !default; // #00afca
$cyan: hsl(204, 71%, 53%) !default; // #3298dc
$blue: hsl(217, 71%, 53%) !default; // #3273dc
$purple: hsl(271, 100%, 71%) !default; // #b86bff
$red: hsl(0, 100%, 45%) !default; // #e60000

// Derived Colours
$primary: #ed1d25;
$primary-light: #363f63;
$info: #70b3fe;

// Custom Colours (Usable with bulma)
$deep-purple: #1a1029;
$deep-purple-invert: findColorInvert($deep-purple);
$deep-purple-light: #1b1730;
$deep-purple-light-invert: findColorInvert($deep-purple-light);
$purple-grey: #6a6573;
$purple-grey-invert: findColorInvert($purple-grey);
$purple-grey-light: #9e97aa;
$purple-grey-light-invert: findColorInvert($purple-grey-light);
$teal: #007e92;
$alice-blue: #f5f6f7;
$electric-red: #e60000;
$wild-sand: #f4f4f4;
$eucalyptus: #219653;
$teal-invert: findColorInvert($teal);
$black-pure: #000000;
$black-ninety: #08081b;
$black-header: #0c0c1a;
$blue-grey-one: #d8dbe0;
$blue-grey-three: #a8acb8;
$blue-grey-six: #525365;
$blue-grey-9: #141827;
$red-50: #e60000;

$custom-colors: (
  'deep-purple': (
    $deep-purple,
    $deep-purple-invert,
  ),
  'deep-purple-light': (
    $deep-purple-light,
    $deep-purple-light-invert,
  ),
  'purple-grey': (
    $purple-grey,
    $purple-grey-invert,
  ),
  'purple-grey-light': (
    $purple-grey-light,
    $purple-grey-light-invert,
  ),
  'cyan': (
    $cyan,
    $white,
  ),
  'red': (
    $red,
    $white,
  ),
  'teal': (
    $teal,
    $teal-invert,
  ),
);

// Custom colours for component styling
$purple-grey-border-colour: #747893;
$dark-grey: #333333;
$medium-light-grey: #999999;
$light-grey: #979797;
$lightest-grey: #efefef;
$background-grey: #f3f3f3;
$background-purple-grey: #f7f6f9;
$border-grey: #d8d8d8;
$storm-grey: #747893;
$dim-grey: #666666;
$main-title: hsl(264, 44%, 11%);
$very-light-purple: #ebebeb;
$light-teal: #00b0ca;
$link-teal: #2f80ed;
$alabaster-variation: #f9f9f9;
$blue-grey-8: #222739;
$blue-grey-7: #393949;
$blue-grey-6: #525365;
$blue-grey-5: #6d6f81;
$blue-grey-4: #8a8d9c;
$blue-grey-3: #a8acb8;
$blue-grey-2: #c9ccd4;
$blue-grey-1: #d8dbe0;
$white-grey: #f2f4f7;
$black-90: #08081b;
$light-grey-additional: #f2f4f7;
$fresh-orange: #eb9700;

// Component Colours
// Menu Component
$menu-nested-list-margin: 0;
$menu-nested-list-padding-left: 0;
$menu-list-border-left: none;

// Typography
$family-sans-serif: 'Roboto', 'Vodafone Rg', sans-serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.75rem !default;
$size-8: auto !default;

$weight-lighter: 100 !default;
$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Spacing
$block-spacing: 1.5rem !default;

// Responsiveness
@import './responsive-variables.scss';

// Miscellaneous
$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;
$card-border: 1px solid #f0f0f0;

// Flags
$variable-columns: true !default;

// Box variables
$box-padding: 0;
$box-radius: 0;

// Tables
$table-head-cell-border-width: 0 0 1px;

// Text styles
@mixin content-common {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}
.content-common {
  @include content-common;
}

@mixin heading-six {
  @include content-common;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.heading-six {
  @include heading-six;
  @media screen and (max-width: $tablet) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
  }
}

@mixin heading-five {
  @include content-common;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

.heading-five {
  @include heading-five;
  @media screen and (max-width: $tablet) {
    @include heading-six;
  }
}

@mixin heading-four {
  @include content-common;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.03em;
}
.heading-four {
  @include heading-four;
  @media screen and (max-width: $tablet) {
    @include heading-five;
  }
}

@mixin heading-three {
  @include content-common;
  font-size: 45px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.03em;
}
.heading-three {
  @include heading-four;
}

.content-large-extra {
  @extend .content-common;
  font-size: 22px;
  line-height: 140%;
  font-weight: 400;
  @media (max-width: $tablet) {
    font-size: 16px;
  }
}

.content-large-regular {
  @extend .content-common;
  font-size: 18px;
  line-height: 140%;
}

.content-normal {
  @extend .content-common;
  font-size: 16px;
  line-height: 140%;
}

.content-small-regular {
  @extend .content-common;
  font-size: 14px;
  line-height: 140%;
}

.badge-small-regular {
  @extend .content-common;
  font-size: 18px;
  line-height: 130%;
}

////////////////// Variants ///////////////

@mixin transparent {
  border: 2px solid;
  box-sizing: border-box;
  background-color: transparent;
}
