@import 'src/sass/variables';
@import 'node_modules/bulma/bulma';

.request-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;

  > .request-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $white;
    height: fit-content;
    min-width: 630px;

    padding: 2rem;
    margin-bottom: 2rem;

    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    > header {
      text-align: center;
      margin-bottom: 2rem;
      > h2 {
        color: $black;
        font-weight: 700;
        font-size: 2rem;

        @media screen and (max-width: $tablet) {
          font-size: 22px;
        }
      }
      > p {
        font-size: 1rem;
        margin-top: 1rem;
      }
    }

    > section {
      width: 100%;
      height: fit-content;
      > form {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .monthly-repayment-block {
          display: flex;
          flex-direction: column;
          padding: 0.75rem;
          text-align: center;
          margin-top: 2rem;
          background-color: $white-grey;

          @include until($tablet) {
            min-width: 0px !important;
          }

          > h3 {
            font-size: 1rem;
            font-weight: 400;
            @media screen and (max-width: $mobile) {
              font-size: 14px;
            }
          }

          .monthly-repayment-amount {
            font-size: 24px;
            font-weight: 700;
            padding: 5px;
            color: $black;
            @media screen and (max-width: $mobile) {
              font-size: 20px;
            }
          }

          .monthly-repayment-tcs {
            font-size: 14px;
            color: $medium-light-grey;
            @media screen and (max-width: $mobile) {
              font-size: 12px;
            }
          }
        }

        @media screen and (max-width: $tablet) {
          gap: 1rem;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      min-width: 90%;
      box-shadow: none;
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $tablet) {
    padding-top: 2rem;
  }
}
