@import 'src/sass/variables';
@import 'node_modules/bulma/bulma';

.select-bank-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;

  .select-bank-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $white;
    height: fit-content;
    max-width: 700px;
    padding: 2rem;

    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    > header {
      text-align: center;
      margin-bottom: 2rem;
      > h2 {
        color: $black;
        font-weight: 700;
        font-size: 32px;
      }

      > p {
        font-weight: 400;
        font-size: 16px;
        margin-top: 1rem;
      }
    }

    > section {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 1rem;
      row-gap: 1rem;

      @media screen and (max-width: $tablet) {
        width: 90%;
        grid-template-columns: 90%;
        justify-content: center;
        place-items: center;
      }
    }

    > p {
      color: $dark-grey;
      font-size: 12px;
      margin-top: 20px;
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: 1.5rem;
      box-shadow: none;
    }
  }
}
