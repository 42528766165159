@import 'src/sass/variables';

.thank-you {
  display: flex;
  justify-content: center;
  align-items: center;

  > .content {
    text-align: center;
    max-width: 700px;
    width: 100%;
    background-color: $white;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 12px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2rem;
    margin-top: 70px;
    font-family: $family-sans-serif;

    @media screen and (max-width: $tablet) {
      box-shadow: none;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-top: 0;
    }

    > h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #0c0c1a;
    }

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin: 2rem auto 1rem;
      max-width: 520px;
    }

    > img {
      margin-top: 2rem;
      width: 100%;
      max-width: 220px;
      height: 260px;
    }
  }
}
