@import 'src/sass/variables.scss';

.custom-button {
  height: 40px;
  min-width: 180px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: $weight-medium;
  margin: 0.5rem;
  cursor: pointer;

  &.is-primary {
    color: $white;
    background-color: $electric-red;
    border: none;
  }

  &.is-secondary {
    color: $black-90;
    background-color: transparent;
    border: 2px solid #0c0c1a;
    border-radius: 8px;
  }

  &.is-tertiary {
    color: $white;
    background-color: transparent;
    border: 2px solid $white;
    border-radius: 8px;
  }

  &:disabled {
    background-color: #393949 !important;
    color: #a8acb8;
    opacity: 0.7;
  }
}
