@charset "utf-8";
@import '../../node_modules/bulma/sass/utilities/functions';
@import './variables.scss';
@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma/sass/utilities/mixins';
@import '~bulma-tooltip';

@mixin scrolling-wrapper {
  justify-content: flex-start;
  //overflow-y: hidden;
}

///////// 0. ELEMENTS //////////////

label {
  &.dark {
    display: block;
    color: $blue-grey-2;
  }
}

///////// 1. CONTAINERS //////////////
///////// 1.1. Layout ////////////////

.form-details-container {
  @extend .columns;
  @include scrolling-wrapper;

  &__content {
    @extend .column;
    @extend .px-5;
    min-width: 600px;
    background-color: $white;
    margin-top: 70px;
    border-radius: 8px;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 25px;

    header {
      @extend .has-text-centered;
      @extend .mb-3;
      color: $black-header;
      padding-bottom: 20px;
    }

    h2 {
      @extend .pt-4;
      @extend .pb-3;
      @extend .is-size-3;
      color: $black-header;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;

      @include until($tablet) {
        font-size: 1.5rem !important;
        padding-bottom: 0.5rem !important;
      }
    }

    .control.is-loading::after {
      right: 2.5rem;
      top: 1.5rem;
    }

    @include until($tablet) {
      min-width: 0 !important;
      margin-top: 0;
      box-shadow: none;
    }
  }

  &__field-id {
    padding-right: 3.5rem !important;

    position: relative;
    .remove-id {
      position: absolute;
      right: 0;
      top: 0.5rem;
      cursor: pointer;
      content: url('../assets/images/remove-icon.svg');
    }

    @include from($tablet) {
      margin-bottom: 1em;
    }
  }

  &__field-center {
    @extend .mt-2;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: $tablet) {
    .field-component {
      margin-bottom: 1em;

      #float-label input.top-field {
        border-radius: 4px !important;
      }
    }
  }
}

.sub-column-paragraph-content {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  color: $dark-grey;
  font-size: 15px;
  text-align: center;
  margin-top: 40px;
}

.sub-footer-paragraph {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  min-width: 600px;

  @include until($tablet) {
    min-width: 0 !important;
  }

  &__content {
    margin-top: 10px;
  }
}

.sub-header-paragraph {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  min-width: 600px;
  margin-top: 20px;
}

.form-lets-get-started-container {
  header {
    @extend .has-text-centered;
  }

  h4 {
    @extend .heading-four;
  }

  &__content {
    @extend .p-6;
    background-color: $white-grey;
    border-radius: 40px;
    margin: auto;
    width: 480px;

    p,
    small {
      color: $blue-grey-4;
    }

    h4 {
      @extend .has-text-purple-grey;
    }

    @include until($tablet) {
      width: 100%;
      padding: 1.5rem !important;
    }
  }

  &__content--divider {
    @extend .pl-6;
    @extend .pr-6;
    @extend .pt-5;

    margin: 0 -3rem;

    @media screen and (max-width: $tablet) {
      box-shadow: inset 0px 20px 20px -10px $blue-grey-1;
      margin: 0 -1.5rem;
    }
  }

  .lets-get-started-input-danger::placeholder {
    color: $primary;
  }
}

.lets-get-started-input {
  border-width: 0px;
  background: $background-purple-grey;
}

///////// 2. COMPONENTS //////////////
///////// 2.1 Icons //////////////////

.is-inline-flex {
  label.radio {
    display: flex;
    align-items: center;
  }
}

.input__radio {
  appearance: none;
  content: url('../assets/images/unselected-radio.svg');
  margin-right: 5px;

  &:checked {
    content: url('../assets/images/selected-radio.svg');
  }
  @include until($tablet) {
    width: 18px;
  }
}

///////// 3. VARIANTS ////////////////
///////// 3.1. Alternatives //////////

.dark {
  color: $white-grey;
}

.is-outlined {
  @include transparent;

  &.is-secondary {
    border-color: $blue-grey-4 !important;
    color: $white-grey !important;
    background-color: transparent !important;
  }
}

///////// 3.2. Spacing ///////////////@at-root

.mt-2-mobile {
  @include until($tablet) {
    margin-top: 0.5rem;
  }
}
