@import 'src/sass/variables';

$footer-height-desktop: 90px;
$footer-height-mobile: 77px;
$footer-height-mobile-large: 100px;
$footer-breakpoint: 770px;
$footer-padding-above: 20px;
$keyboard-up-breakpoint: 600px;

.page-content {
  @media screen and (max-width: $footer-breakpoint) {
    padding-bottom: $footer-height-mobile + $footer-padding-above;
  }

  @media screen and (min-width: $footer-breakpoint) {
    padding-bottom: $footer-height-desktop + $footer-padding-above;
  }
}

.footer-content {
  @media screen and (max-width: $footer-breakpoint) {
    height: $footer-height-mobile;
  }

  @media screen and (min-width: $footer-breakpoint) {
    height: $footer-height-desktop;
  }
}

.NavigationFooter {
  @extend .footer-content;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #222739;
  backdrop-filter: blur(5px);

  z-index: 9;

  display: flex;
  align-content: center;
  align-items: center;
  column-gap: 1rem;

  padding: 8px 40px;

  @media screen and (max-height: $keyboard-up-breakpoint) {
    position: relative;
  }

  .btn-placeholder {
    min-width: unset;
    width: 180px;
  }

  button {
    margin: 0;
    width: 100%;
  }

  @media screen and (max-width: $tablet) {
    height: fit-content;
    padding: 1rem 0.5rem;

    .btn-placeholder {
      flex-grow: 1;

      &:has(.is-tertiary) {
        flex-grow: 0.3;

        &:has(+ .btn-placeholder:empty) {
          flex-grow: 1;
        }
      }

      &:empty {
        display: none;
      }
    }

    button {
      min-width: unset;
    }
  }
}
