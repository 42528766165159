@font-face {
  font-family: 'Vodafone Rg';
  src: local('Vodafone Rg'), url(./VodafoneRegular.ttf) format('truetype');
}
@font-face {
  font-family: 'Vodafone Light';
  src: local('Vodafone Light'), url(./VodafoneLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Vodafone Bold';
  src: local('Vodafone Bold'), url(./VodafoneRegularBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-Black.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-LightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./Roboto/Roboto-BlackItalic.ttf) format('truetype');
  font-weight: bolder;
  font-style: italic;
}

.is-family-vodafone-reg {
  font-family: 'Vodafone Rg';
}

.is-family-vodafone-light {
  font-family: 'Vodafone Light';
}

.is-family-vodafone-bold {
  font-family: 'Vodafone Bold';
}
