@import '../../sass/responsive-variables.scss';

.ToastMessageQueue {
  position: fixed;
  z-index: 10;
}

@media (min-width: $tablet) {
  .ToastMessageQueue {
    left: 25%;
    bottom: 122px;
  }

  .login-background + .toast-help-container {
    .ToastMessageQueue {
      left: 35%;
    }
  }
}

@media (max-width: $tablet) {
  .ToastMessageQueue {
    bottom: 122px;
    margin-left: auto;
    margin-right: auto;
    left: 16px;
    right: 16px;
  }
}
