@import '../../sass/variables.scss';

.ToastMessage {
  color: $white;

  @media (min-width: $tablet) {
    width: 50vw;
  }
  border-radius: 20px;
  padding-left: 25px;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-bottom: 30px;
  background: $white-grey;
  word-wrap: break-word;
  word-break: break-word;

  .action-text {
    color: $white;
    padding-left: 4px;
    font-weight: 700;
    text-decoration: underline;
  }

  .field-text {
    display: flex;
    align-items: center;
    color: $blue-grey-6;

    .message {
      padding-left: 20px;

      .message-main {
        color: $blue-grey-8;
      }

      .message-subtitle {
        color: $blue-grey-5;
      }
    }
  }

  .error-image {
    width: 35px;
    height: 35px;
  }

  .info-image {
    width: 26px;
    height: 26px;
  }

  .cross-container {
    cursor: pointer;
    color: $blue-grey-4;
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon-icon-close {
      font-size: 25px;
    }
  }

  .action-button {
    background-color: $red;
    color: $white;
    height: 40px;
    width: 138px;
    line-height: 0.75;
    text-align: center;
    cursor: pointer;
    border-radius: 27px;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    margin: 10px 0;
  }
}
