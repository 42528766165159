@import 'src/sass/variables.scss';

.field-component {
  @media (min-width: $tablet) {
    margin-bottom: 1em;
  }
  #float-label {
    display: flex;
    position: relative;

    input {
      width: 100%;
      height: 65px;
      padding: 14px 16px 0 14px;
      outline: 0;
      border: 1px solid $blue-grey-1;
      background: $white-grey;
      font-size: 16px;
      letter-spacing: -0.01em;
      border-radius: 4px;

      &.password {
        font-size: 32px;
        color: $blue-grey-six;
      }

      &.dark {
        background: $blue-grey-8;
        color: $white-grey;
        border: 1px solid $blue-grey-8;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px $blue-grey-6 inset !important;
          -webkit-text-fill-color: $white !important;
          -webkit-transition-delay: 99999999s;
        }
        &:focus {
          background: $blue-grey-7;
        }
      }

      @media (max-width: $tablet) {
        line-height: 45px;
        border-radius: 0px;

        &.top-field {
          border-radius: 4px 4px 0px 0px !important;
        }

        &.bottom-field {
          border-radius: 0px 0px 4px 4px !important;
        }

        &.top-field.bottom-field {
          border-radius: 4px !important;
        }
      }
    }

    label {
      position: absolute;
      transform: translate(0, 26px) scale(1);
      transform-origin: top left;
      transition: all 0.2s ease-out;
      font-size: 16px;
      left: 15px;
      color: $dark-grey;
      pointer-events: none;

      &.Active {
        transform: translate(0, 7px) scale(0.75);
      }

      &.is-expanded:not(.dark) {
        color: $blue-grey-4;
      }
    }

    .input-password-right-icon {
      position: absolute;
      top: 22px;
      z-index: 9;
      right: 16px;
      cursor: pointer;
    }

    .icon-eye-show {
      font-size: 25px;
      color: $blue-grey-4;
    }

    .icon-eye-hide {
      font-size: 25px;
      color: $blue-grey-4;
    }
  }

  #float-label.errorState:before {
    background: $red;
  }

  #float-label.has-after-line:after,
  .float-label.has-after-line:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0px;
    right: 16px;
    left: 14px;
    z-index: 4;
  }

  #float-label.has-after-line:not(.errorState):after,
  .float-label.has-after-line:not(.errorState):after {
    background: $blue-grey-5;
  }

  #float-label.has-after-line.errorState:after,
  .float-label.has-after-line.errorState:after {
    background: $red;
  }
}

.field-error {
  padding-left: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: $red;
  text-align: left;
  padding-top: 5px;
  min-height: 22px;
  display: flex;
  align-items: center;

  .error-image {
    padding-right: 7px;
  }

  @media (max-width: $tablet) {
    padding-bottom: 1em;
  }
}

.autocomplete-wrapper {
  width: 100%;
}

.autocomplete-container-floating-input {
  // can't get autocomplete to pick the styles up if its within .field-component
  width: 100%;
  position: relative;
  height: 65px;
  display: flex;

  .no-caret {
    caret-color: transparent !important;
  }

  .autocomplete-input {
    position: relative;
    width: 100%;
    height: 65px;

    .drop-down-closed-right-icon {
      position: absolute;
      top: 22px;
      z-index: 9;
      right: 16px;
    }

    .icon-drop-down-arrow-reskin {
      font-size: 20px;
      color: $blue-grey-5;
      &.dark {
        color: $blue-grey-3;
      }
    }

    .icon-close-icon-white-small {
      font-size: 20px;
      color: $blue-grey-5;
      &.dark {
        color: $blue-grey-3;
      }
    }

    .auto-input {
      width: 100%;
      height: 65px;
      padding: 14px 16px 0 14px;
      outline: 0;
      border: 1px solid $blue-grey-1;
      background: $blue-grey-1;
      font-size: 16px;
      border-radius: 4px;

      @media (max-width: $tablet) {
        border-radius: 0px;

        &.top-field {
          border-radius: 4px 4px 0px 0px;
        }

        &.bottom-field {
          border-radius: 0px 0px 4px 4px;
        }

        &.top-field.bottom-field {
          border-radius: 4px;
        }
      }

      &.dark {
        background: $blue-grey-8;
        color: $white-grey;
        border: 1px solid $blue-grey-8;

        &:focus {
          background: $blue-grey-7;
        }
      }

      &.is-expanded.dark {
        background-color: $blue-grey-7 !important;
      }
    }

    .f-label {
      position: absolute;
      top: 0px;
      transform: translate(0, 26px) scale(1);
      transform-origin: top left;
      transition: all 0.2s ease-out;
      font-size: 16px;
      color: $blue-grey-4;
      pointer-events: none;
      left: 14px;

      &.Active {
        transform: translate(0, 12px) scale(0.75);
      }
    }
  }

  .autocomplete-input.errorState:after {
    background: $red;
  }

  .autocomplete-list {
    border-radius: 0px 0px 8px 8px !important;
    width: 100% !important;
    position: absolute;
    border-radius: 4px;
    top: 55px !important; // being overwritten by component
    left: 0 !important; // being overwritten by component
    z-index: 10;
    overflow-y: auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    max-height: 12rem;

    // FF Scrollbar
    scrollbar-color: $medium-light-grey $white-grey;
    scrollbar-width: thin;

    // V8
    &::-webkit-scrollbar {
      background: $white-grey;
      border-radius: 0 0 8px 0;
      width: 8px;
      border: 4px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $medium-light-grey;
      border-radius: 8px;
      border: 4px solid transparent;
    }

    .autocomplete-item {
      cursor: pointer;
      position: relative;
      background: white;
      text-align: left;
      background: $white-grey;
      color: $dark-grey;

      &.highlighted {
        background: $blue-grey-3;
      }

      &:after {
        content: '';
        height: 1px;
        background: $blue-grey-5;
        position: absolute;
        bottom: 0px;
        right: 16px;
        left: 14px;
        z-index: 4;
      }
    }
  }
}
