@import '/src/sass/variables.scss';

body.fixed-body {
  position: fixed;
  width: 100%;
}

article.address-modal-overlay {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1418277f;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: $tablet) {
    overflow: auto;
  }

  > section.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    background: $white;
    border-radius: 8px;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    padding: 5rem;
    max-height: 90vh;

    &.small {
      width: min(90%, 40rem);
      min-height: min(23rem, 90vh);
    }

    &.medium {
      padding: 3rem 5rem;
      width: min(90%, 50rem);
      min-height: min(38rem, 90vh);
    }

    &.large {
      padding: 3rem 5rem;
      width: min(90%, 60rem);
      min-height: min(48rem, 90vh);
    }

    @media screen and (max-width: $tablet) {
      max-height: none;

      &.small,
      &.medium,
      &.large {
        padding: 3rem 1rem;
        height: 100%;
        width: 100vw;
        overflow: auto;
      }
    }

    > img.close-icon {
      position: absolute;

      @media screen and (min-width: $tablet) {
        top: 1.5rem;
        right: 2.2rem;
      }

      @media screen and (max-width: $tablet) {
        top: 0.8rem;
        right: 1.5rem;
        margin-top: 1.5rem;
      }

      cursor: pointer;
    }

    > section.scroll-box {
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: $tablet) {
        justify-content: space-evenly;
        height: max-content;
        margin-top: 1.5rem;
      }
    }
  }
}
