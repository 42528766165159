@import 'src/sass/variables';

.disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 3rem 0 1rem;
  padding: 0 1.25rem;
  text-align: center;

  > img {
    margin-bottom: 2rem;
    width: 250px;
    height: 50px;

    @media screen and (max-width: $mobile) {
      margin-bottom: 0.5rem;
      width: 155px;
      height: 32px;
    }
  }

  > p {
    color: #333333;
    font-size: 12px;

    a {
      color: #333333;
      text-decoration: underline;
      white-space: nowrap;

      &:hover {
        color: #007c92;
      }
    }

    @media screen and (max-width: $mobile) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: $tablet) {
    margin-top: 0;
    &.is-fixed {
      position: fixed;
      left: 0;
      bottom: 0;
    }
  }
}
