@import 'src/sass/variables.scss';

.report-issue {
  display: flex;
  justify-content: center;
  align-items: center;

  > .content {
    text-align: center;
    max-width: 700px;
    width: 100%;
    background-color: $white;
    padding: 2rem;
    margin-top: 70px;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);

    > h2 {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: $tablet) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-top: 0;
      box-shadow: none;
    }
  }
  @media screen and (max-width: $tablet) {
    .field-component {
      margin-bottom: 1em;

      > #float-label input {
        border-radius: 4px;
      }
    }
  }
}
