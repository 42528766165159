@import 'src/sass/variables.scss';

.page-header-common {
  > .vodapay-logo {
    width: 40px;
  }
}

.application-failed {
  display: flex;
  align-items: center;
  justify-content: center;

  > .content {
    max-width: 700px;
    width: 100%;
    margin-top: 0;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1), 0px 7px 12px rgba(0, 0, 0, 0.1),
      0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2rem;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;

    @media screen and (max-width: $tablet) {
      box-shadow: none;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-top: 0;
    }
    > header {
      font-family: $family-sans-serif;
      text-align: center;
      > h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
      }
      > p {
        margin-top: 2rem;
        font-size: 16px;
        color: $black;

        a {
          margin-left: 3px;
          color: $teal;
        }
      }
    }

    > section {
      display: flex;
      margin-top: 1.5rem;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }
    }
  }
}
