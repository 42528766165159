@import 'src/sass/variables.scss';

.Checkbox {
  & > div {
    @media (max-width: $tablet) {
      margin-top: 1em;
    }

    cursor: pointer;
    min-height: 22px;
    display: flex;
    align-items: center;
    padding-bottom: 1em;

    span {
      padding-left: 10px;
      font-size: 1rem;
      color: $black-ninety;
      @media screen and (max-width: $mobile) {
        font-size: 12px;
      }
    }
  }

  &.disabled {
    .checkbox-label {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
}
